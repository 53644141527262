<template>
  <div style="background: #F6F6F6; height: 100%;">
    <van-nav-bar
      :title="orderName"
      left-arrow
      placeholder
      fixed
      @click-left="$router.go(-1)"
    />

    <div style="padding-bottom: 80px;">
      <div
        style="width: 96%; 
        background: #fff;
        padding: 12px 3% 20px;
        box-sizing: border-box;
        border-radius: 8px;
        margin: 10px auto;"
        v-for="(item, index) in takeoutOrderGoods"
        :key="index"
      >
        <div style=" display: flex;">
          <img style="width: 55px; height: 55px; margin-right: 3%;" :src="item.goodsUrl[0]" alt="">
          <div  style="flex: 1;">
            <p style="font-size: 14px;">{{ item.goodsName }}</p>
            <div>
              <van-tag type="primary" color="#EEEEEE" text-color="#999">
                <span style="font-size: 12px;">{{ item.goodsStandardNames }}</span>
              </van-tag>
              <!-- &nbsp;
              <van-tag type="primary" color="#EEEEEE" text-color="#999">
                <span style="font-size: 12px;">香辣</span>
              </van-tag> -->
            </div>
          </div>
        </div>

        <div
          class="Ge"
          style="margin: 17px 0 18px; 
          border: 1px solid #E0E0E0; 
          border-radius: 4px;"
        >
          <van-field
            v-model="content[index]"
            rows="4"
            type="textarea"
            maxlength="50"
            placeholder="说说商家的亮点和不足吧～"
            show-word-limit
          />
        </div>

        <div>
          <div style="display: flex; align-items: center;">
            <span style="font-size: 14px; #333; margin-right: 20px;">口味</span>
            <div>
              <van-rate
                v-model="taste[index]"
                void-icon="star"
                gutter="15"
                size="24"
                color="#FF5200"
                void-color="#999"
                @change="onchange('taste', index)" 
              />
              <div 
                style="font-size: 12px; color: #999;
                width: 180px;
                display: flex;
                margin-top: 3px;
                justify-content: space-between;"
              >
                <span style="transform: scale(.83);">极差</span>
                <span style="transform: scale(.83);">较差</span>
                <span style="transform: scale(.83);">一般</span>
                <span style="transform: scale(.83);">不错</span>
                <span style="transform: scale(.83);">很棒</span>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center; margin: 10px 0;">
            <span style="font-size: 14px; #333; margin-right: 20px;">包装</span>
            <div>
              <van-rate
                v-model="pack[index]"
                void-icon="star"
                gutter="15"
                size="24"
                color="#FF5200"
                void-color="#999"
                @change="onchange('pack', index)"
              />
              <div 
                style="font-size: 12px; color: #999;
                width: 180px;
                display: flex;
                margin-top: 3px;
                justify-content: space-between;"
              >
                <span style="transform: scale(.83);">极差</span>
                <span style="transform: scale(.83);">较差</span>
                <span style="transform: scale(.83);">一般</span>
                <span style="transform: scale(.83);">不错</span>
                <span style="transform: scale(.83);">很棒</span>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center;">
            <span style="font-size: 14px; #333; margin-right: 20px;">配送</span>
            <div>
              <van-rate
                v-model="delivery[index]"
                void-icon="star"
                gutter="15"
                size="24"
                color="#FF5200"
                void-color="#999"
                @change="onchange('delivery', index)"
              />
              <div 
                style="font-size: 12px; color: #999;
                width: 180px;
                display: flex;
                margin-top: 3px;
                justify-content: space-between;"
              >
                <span style="transform: scale(.83);">极差</span>
                <span style="transform: scale(.83);">较差</span>
                <span style="transform: scale(.83);">一般</span>
                <span style="transform: scale(.83);">不错</span>
                <span style="transform: scale(.83);">很棒</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <van-goods-action>
      <van-goods-action-button
        text="发布评价"
        color="linear-gradient(to right, #FF7200, #FF3C00)"
        @click="goodsevaluate"
      />
    </van-goods-action>
  </div>
</template>

<script>
import { goodsevaluate, findorders } from '@/api/Ge'
import { mapState } from 'vuex'
import qs from 'qs'
  export default {
    data () {
      return {
        content: [],
        taste: [],
        pack: [],
        delivery: [],
        orderName: '',
        takeoutOrderGoods: []
      }
    },
    created () {
      this.findorders()
    },
    computed: {
      ...mapState(['session'])
    },
    methods: {
      goodsevaluate () {
        const takeoutOrderGoods = JSON.parse(JSON.stringify(this.takeoutOrderGoods))
        const content = JSON.parse(JSON.stringify(this.content))
        if (content.length != this.takeoutOrderGoods.length) {
          this.$toast('请输入商家评价')
          return
        }
        const taste = JSON.parse(JSON.stringify(this.taste))
        const pack = JSON.parse(JSON.stringify(this.pack))
        const delivery = JSON.parse(JSON.stringify(this.delivery))
        takeoutOrderGoods.forEach((item, index) => {
          let params = {}
          params.id = item.id
          params.context = content[index] ? content[index] : ''
          params.taste = taste[index] ? taste[index] : ''
          params.pack = pack[index] ? pack[index] : ''
          params.delivery = delivery[index] ? delivery[index] : ''
          goodsevaluate(qs.stringify(params)).then(res => {
            if (res.data.code === 0) {
              this.$toast('评价成功')
              this.$router.go(-1)
            }
          })
        })
        
      },

      // 订单详情
      findorders () {
        findorders(`orderId=${this.$route.query.id}`).then(res => {
          if (res.data.code === 0) {
            this.orderName = res.data.data.orderName
            let records = res.data.data.takeoutOrderGoods
            records.forEach(item => {
              item.goodsStandardNames = item.goodsStandardNames.split(':')[1]
              console.log(item.goodsStandardNames, '内容')
            })
            this.takeoutOrderGoods = records
          }
        })
      },

      onchange (tyupe, index) {
        if (tyupe == 'taste') {
          if (!this.pack[index]) {
            this.pack[index] = 1
          }
          if (!this.delivery[index]) {
            this.delivery[index] = 1
          }
        }

        if (tyupe == 'pack') {
          if (!this.taste[index]) {
            this.taste[index] = 1
          }
          if (!this.delivery[index]) {
            this.delivery[index] = 1
          }
        }

        if (tyupe == 'delivery') {
          if (!this.taste[index]) {
            this.taste[index] = 1
          }
          if (!this.pack[index]) {
            this.pack[index] = 1
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.Ge {
  /deep/ .van-field__control {
    font-size: 12px;
  }
}
</style>